@import 'common';

.our-strengths {
  .strengths-detail {
    @extend .detail;
    text-align: left;
    margin-bottom: 2rem;

    h3 {
      text-align: left;
    }

    .detail-button {
      margin-bottom: 0rem;
      @media (max-width: 767px) {
        margin-bottom: 2rem;
      }
    }
  }

  .strength-divider {
    border: none; // hrのデフォルトのボーダーを削除
    height: 0.1rem; // hrの太さを設定
    background-color: #aaa; // hrの色を設定
    margin: 2rem 0; // hrの上下のマージンを設定

    @media (max-width: 767px) {
      height: 0;
      margin: 2rem 0
    }
  }
}