@import "../../styles/variables";

@mixin member-container(
  $left-width,
  $left-height,
  $left-id-text-size,
  $left-id-text-height-adjust-ratio,
  $left-english-name-height,
  $left-egname-font-size,
  $right-title-letter-spacing,
  $right-position-letter-spacing
  // $right-jpname-font-size
  )
{
  display: flex;
  flex-direction: column;
  // margin-bottom: 3rem;
  
  @media (min-width: map-get($grid-breakpoints,md)) {
    flex-direction: row;
    height: 21rem;
    margin-bottom: 7rem;
  }

  .left-side {
    position: relative;
    flex: auto;
    height: $left-height;
    margin-bottom: 4rem;
    // margin-bottom: 0 0 3rem 0;      

    @media (min-width: map-get($grid-breakpoints,md)) {
      flex: 0 0 $left-width; /* 左ペインの幅を30%に固定 */
      min-width: 0;
      width: 100%;
      margin-bottom: 0;
    }

    .id {
      position: absolute;
      margin: 0;
      line-height: 1;//$left-id-text-height-adjust-ratio;
      left: 0;
      top: 0;
      transform: translateX(-50%);
      font-size: $left-id-text-size;
      font-weight: 700;
      color: $jyu-sub;
      letter-spacing: 0.2rem;
      transform-origin: bottom left;
      transform: translateY(-100%) rotate(90deg);
  
      .number {
        font-size: calc(#{$left-id-text-size} * 0.5);
        line-height: 0;
        margin: 0 calc(#{$left-id-text-size} * 0.2);
        border-top: calc(#{$left-id-text-size} * 0.15) solid;
      }
    }

    .english-name {
      position: absolute;
      left: calc(#{$left-id-text-size} + 0.5rem);
      top: 0rem;
      line-height: 1;
      font-size: $left-egname-font-size;
      font-weight: 700;
      color: $jyu-sub;

      .last {
        line-height: 1;
      }

      .first {
        line-height: 2;
      }

      @media (min-width: 576px) {
        left: calc(#{$left-id-text-size} + 0.5rem);
        top: 0rem;
        line-height: 1;
        font-weight: 700;
        color: $jyu-sub;
  
        .last {
          line-height: 1;
        }
  
        .first {
          line-height: 2;
        }
      }
    }

    .image {
      position: absolute;
      left: calc(#{$left-id-text-size} * #{$left-id-text-height-adjust-ratio});
      top: calc(#{$left-egname-font-size} * 3);
      width: calc(100% - #{$left-id-text-size});
      height: calc(100% - #{$left-english-name-height});
      object-fit: cover;
      object-position: center -5rem;
      @media (min-width: 768px) {
        object-position: center top;
      }
    }
  }

  .right-side {
    flex: 1; /* 残りの幅を占める */
    height: 100%;
    margin: 0 0 0 calc(#{$left-id-text-size} * #{$left-id-text-height-adjust-ratio});
    align-items: center;

    @media (min-width: map-get($grid-breakpoints,md)) {
      flex: 1;
      min-width: 0;
      width: 100%;
      margin: 0 0 0 3rem;
    }

    .title {
      font-size: $left-id-text-size;
      font-weight: 700;
      line-height: 1.2;
      letter-spacing: $right-title-letter-spacing;
      margin-bottom: 0.8rem;
      color: $jyu-sub;

      @media (min-width: map-get($grid-breakpoints,md)) {
        margin-top: calc(#{$left-egname-font-size} * 3 + 6rem);
      }

      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: -5rem;
        top: 0;
        width: 8rem;
        border-top: 0.1rem solid $jyu-sub;
        transform-origin: 5rem 0;
        transform: rotate(-60deg) translate(0.5rem, 0.5rem);
      }
    }

    .position {
      font-family: 'Noto Sans JP', sans-serif;
      font-size: calc(#{$left-id-text-size} * 0.55);
      font-weight: 100;
      letter-spacing: $right-position-letter-spacing;
    }

    .japanese-name {
      display: inline-block;
      font-size: calc(#{$left-id-text-size} * 0.8);
      font-weight: 500;
      letter-spacing: 0.3rem;
      margin-bottom: 1.4rem;

      position: relative;
      &::after {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -0.1rem;
        width: 100%;
        border-bottom: 0.3rem solid $jyu-yellow;
        border-radius: 0.1rem;
      }
    }

    .introduction {
      font-size: calc(#{$left-id-text-size} * 0.4);
      font-weight: 500;
      line-height: 1.8;
      padding-right: 1rem;
      @media (min-width: map-get($grid-breakpoints,md)) {
        font-size: calc(#{$left-id-text-size} * 0.45);
        padding-right: 0;
      }
    }
  }
}

.members {

  h2 {
    font-family: 'Domus Titling', sans-serif;
    font-size: 2.5rem;
    color: $jyu-sub;
    letter-spacing: 0.2rem;
    font-weight: 100 !important;
    -webkit-text-stroke: 1px black;

    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -1.7rem;
      width: 7.5rem;
      border-bottom: 0.3rem solid $jyu-lightgreen;
      border-radius: 0.1rem;
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
      font-size: 2rem;
      &::after {
        width: 6.5rem;
      }
    }
  }

  .member-container {
    @include member-container(
      14rem, calc(15rem + 25vw),
      3.2rem, 0.8, 3.2rem,
      1.5rem,
      0.1rem,0.15rem
    );
    margin-bottom: 5rem;

    @media (min-width: map-get($grid-breakpoints,md)) {
      @include member-container(
        16rem, calc(12rem + 25vw),
        2.2rem, 0.75, 3.2rem,
        1rem,
        0.1rem,0.15rem);
      margin-bottom: 3rem;
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
      @include member-container(
        17rem, 26rem, //calc(10.5rem + 25vw)
        2.4rem, 0.79, 2.8rem,
        1rem,
        0.25rem,0.28rem);
      margin-bottom: 3rem;
    }
  }
}