@import "../../styles/variables";

$animation-1: 0.2s;
$animation-2: 0.1s;

.animated-header {
  position: absolute !important;
  width: 100vw;
  top: 0;
  z-index: 100;
  
  background-color: transparent;
  
  border: none !important;
  padding: 0 !important;
  transition: transform $animation-1 ease-in-out;
  animation-delay: 0.1s;
  animation-fill-mode: backwards;

  // @keyframes slideInFromBottom {
  //   from {
  //     transform: translateY(210%);
  //   }
  //   to {
  //     transform: translateY(0);
  //   }
  // }

  .navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: auto;
    z-index: inherit;

    &.active {
      height: 100vh;
    }

    @media (min-width: map-get($grid-breakpoints,lg)) {
      position: inherit;
    }
  }

  .menu-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    pointer-events: none;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.8);
    transition: opacity 0.05s ease-in-out;

    &.active {
      height: 100vh;
      z-index: 100;
      pointer-events: auto;
      display: block;
      opacity: 1;
    }
  }

  .navbar-toggler {
    line-height: 2;
    font-size: 2rem;
    color: white;
    border: none;
    &:focus {
      box-shadow: none;
    }
  }

  .navbar-toggler-icon {
    fill: white;
    border-color: rgb(255,102,203);
  }

  .menu-item {
    color: white;
    padding: 1.5rem auto;
    height: 6rem;
    overflow: hidden;
    cursor: pointer;

    .ja, .en {
      @keyframes slideInFromBottom {
        from {
          transform: translateY(300%);
        }
        to {
          transform: translateY(0);
        }
      }
      
      animation: slideInFromBottom 0.8s ease-in-out;
      animation-delay: $animation-2;
      animation-fill-mode: backwards;
    }
    .ja {
      font-size: 1.5rem;
    }

    .en {
      font-size: 1.0rem;
    }

    @media (min-width: map-get($grid-breakpoints,lg)) {
      height: auto;
      
      .ja, .en {
        animation: unset;
      }
      .ja {
        font-size: 1.0rem;
      }
      .en {
        font-size: 0.7rem;
      }
    }

    @media (min-width: map-get($grid-breakpoints,xl)) {
      height: auto;
      
      .ja {
        font-size: calc( max(1.0rem, 1.0rem + 1vw - 12px) );
      }
      .en {
        font-size: calc( max(0.7rem, 0.7rem + 1vw - 12px) );
      }
    }
  }

  .hamberger {
    position: fixed;
    top: 2.3rem;
    right: 1.5rem;
  }

  .blandSp {
    position: absolute;
    top: 2.3rem;    
    left: 2rem;
    transform: translateX(0);
    height: 5rem;

    @media (min-width: 576px) { 
      left: 50%;
      transform: translateX(-50%);
    }
  }

  .bland {
    height: 4.5rem;
    cursor: pointer;
    z-index: inherit;
    @media (min-width: map-get($grid-breakpoints,lg)) {
      height: 4rem;
    }
    @media (min-width: map-get($grid-breakpoints,xl)) {
      height: calc( max(4rem, 4rem + 4vw - 48px) );
    }
  }

  .item {
    margin-top: 1rem;
  }

  .menu-overlay {
    z-index: 200;
  }
}
