@import "../../styles/variables";

.tag {
  display: inline-block;
  color: white;
  font-size: 0.8rem;
  font-weight: 800;
  margin: 0.2rem 0.6rem 0.2rem 0.2rem;
  padding: 0.1rem 0.5rem;
  border-radius: 0.3rem;
  background-color: $jyu-green;
  cursor: pointer;
}