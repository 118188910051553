@import "../../styles/variables";

@keyframes dots {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes gradation{
  0%{
    opacity: 0.2;
    background-position: 0% 0%;
  }
  10%{
    opacity: 0.8;
    background-position: 0% 0%;
  }
  20%{
    opacity: 0.2;
  }
  30%{
    opacity: 0.8;
    background-position: 100% 100%;
  }
  40%{
    opacity: 0.2;
  }
  50%{
    opacity: 0.8;
  }
  60%{
    opacity: 0.2;
  }
  70%{
    opacity: 0.8;
  }
  80%{
    opacity: 0.2;
  }
  90%{
    opacity: 0.8;
  }
  100%{
    opacity: 0.2;
    background-position: 100% 100%;
  }
}

.under-construction {
  height: 80vw;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  background: linear-gradient(-30deg, white, white 35%, $jyu-green 50%, white 65%, white);
  background-size: 300% 300%;
  background-position: 0% 20%;

  animation-name: gradation;
  animation-duration: 8.0s;
  animation-iteration-count: infinite;
  // animation-direction: alternate;
  // animation-timing-function: ease-in-out;
  animation-fill-mode: backwards;

  div {
    font-size: 3rem;
    font-weight: 400;

    .dots-animation-1 {
      animation: dots 1.5s infinite;
      animation-delay: 0s;
    }

    .dots-animation-2 {
      animation: dots 1.5s infinite;
      animation-delay: 0.1s;
    }

    .dots-animation-3 {
      animation: dots 1.5s infinite;
      animation-delay: 0.2s;
    }
  }
}
