@import "../../styles/variables";
@import "../../styles/global.scss";

.blog-detail {
  .blog-content {
    margin-bottom: 3rem;

    .eyecatch-wrap {
      position: relative;
      width: 100%;
      aspect-ratio: 16 / 9;

      .img {
        position: absolute;
        width: 100%;
        aspect-ratio: 16 / 9;
        border-radius: 0.2rem;
        object-fit: cover;
      }
      .title {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;  // horizontally center text
        align-items: center;  // vertically center text
        text-align: center;

        font-size: 1.8rem;
        color: white;
        font-weight: 800;
      }
    }

    .blog-body {

      h3 {
        @include h3-font-size();
        font-weight: 800;
        margin: 2rem 0 1rem 0;
  
        display: inline-block;
        margin-bottom: 2rem !important;
      
        position: relative;
        &::after {
          content: "";
          display: block;
          position: absolute;
          left: 0rem;
          bottom: -0.4rem;
          height: 0.3rem;
          width: 100%;
          border-bottom: 0.3rem solid $jyu-green;
          border-radius: 0.1rem;
        }
      }
  
      h4 {
        @include h4-font-size();
        margin: 1rem 0 1rem 0;
      }
  
      h5 {
        @include h4-font-size();
        margin: 1rem 0 1rem 0;
      }
  
      p {
        @include p2-font-size();
        @media (min-width: map-get($grid-breakpoints,lg)) {
          @include p1-font-size();
        }
        letter-spacing: 0;
        margin: 0.5rem 0 2rem 0;
      }

      p>img { //insert img
        display: block;
        margin-left: auto;
        margin-right: auto;
        
        width: 80%;
        height: auto;
      }

      ul {
        margin-bottom: 2rem;
        &>li {
          @extend .p-base;
          margin: 0.2rem 0 0.5rem 0;
        }
      }
    }

    .table-of-content {
      position: sticky;
      top: 0;

      p.h3 {
        @include p2-font-size();
        font-weight: 400;
        margin-top: 0.5rem;
        margin-bottom: 0rem;
        &.active {
          font-weight: 800;
        }
      }

      p.h4 {
        @include p2-font-size();
        font-weight: 400;
        margin-top: 0.2rem;
        margin-bottom: 0;
        margin-left: 0.8rem;
        &.active {
          font-weight: 800;
        }
      }

      p.h5 {
        @include p2-font-size();
        font-weight: 400;
        margin-top: 0.2rem;
        margin-bottom: 0;
        margin-left: 1.5rem;
        &.active {
          font-weight: 800;
        }
        &:before {
          content: '-';
        }
      }
    }
  }
}

.pagination {
  .item {
    .page-link {
      color: $jyu-green;
      border: none;
      font-weight: 800;
      border-radius: 0.2rem;
      margin: 0 1rem;
    }
  }
  .item.active {
    .page-link {
      color: white;
      background-color: $jyu-lightgreen;
      border-radius: 0.2rem;
    }
  }
}