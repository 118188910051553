@import "../../styles/variables";

.hero {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;

  $hero-margin-left: 6rem;

  .hero-image-icon {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 50;
    margin-left: -3vw;
    margin-bottom: 0;
    width: 58vw;
  }
  
  .hero-main-message {
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 50;
    margin-left: 6vw;
    margin-bottom: 10vw;
    color: white;
    font-size: 0.8rem;
    font-weight: 600;
    letter-spacing: 0.2rem;

    @media (min-width: map-get($grid-breakpoints,md)) {
      font-size: 1rem;
      font-weight: 600;
      letter-spacing: 0.5rem;
    }

    @media (min-width: map-get($grid-breakpoints,lg)) {
      font-size: 1.2rem;
      font-weight: 600;
      letter-spacing: 0.5rem;
    }
  }
}
