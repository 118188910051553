@import "../../styles/variables";
@import "../../styles/global.scss";

.blog-tile {
  position: static;
  width: 100%;
  aspect-ratio: 1;

  .img-wrap {
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 16 / 9;
    overflow: hidden;
    transition: border 0.2s ease-in;

    .img {
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 0.2rem;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      transition: transform 0.2s ease-in;
      
      &:hover {
        transform: scale(1.1);
      }
    }

    .overlay {
      position: absolute;
      width: 100%;
      aspect-ratio: 16 / 9;
      mix-blend-mode: multiply;
      transition: border 0.2s ease-in;
      transition: background 0.2s ease-in;

      &:hover {
        background-color: rgba(92, 146, 145, 0.4);
        border: solid;
        border-color: $jyu-green;
        border-width: 0.2rem;
        border-radius: 0.1rem;
      }
    }

    .tags {
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }
  .title {
    @include p1-font-size();
    line-height: 1.1;
    letter-spacing: 0.1;
    margin-top: 0.8rem;
  }
}