.form-label {
  font-weight: bold;
  margin-bottom: 0.5rem;
}

.button {
  margin-top: 1rem; // 送信ボタンと入力項目の間にスペースを追加
}

$spin-duration: 10s;

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

// .spin {
//   animation: spin $spin-duration linear infinite;
// }

