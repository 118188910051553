@import "../../styles/variables";
@import "../../styles/global";
@import 'common';

.business-overview {
  margin-bottom: 5rem;

  .swiper {
    width: 100%;
    // height: 88vw;
    height: 26.5rem;
    --swiper-navigation-color: #fff;
    --swiper-pagination-color: #fff;
  }
  
  .swiper-slide {
    text-align: left;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    // padding: 5vw 5vw;
  }
  

  .item-wrap {
    position: relative;
    $text-width: 20vw;
    // height: 26.5rem;
    height: 100%;

    .item-image {
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
      width: calc(100% - #{$text-width});
      height: 100%;
      border-radius: 2rem 0 0 0;
      margin-left: $text-width;
      background-size: cover;
      background-attachment: fixed;
    }

    .item-title {
      position: absolute;
      @extend .h3-title;
      font-weight: 200;
      color: black;
      writing-mode: vertical-lr;
      margin: 0 2rem;

      &::after {
        border: none;
      }
    }

    .item-title-english{
      position: absolute;
      margin: auto 4.3rem 0 4.3rem;
      color: $jyu-sub;
      writing-mode: vertical-lr;
    }

    .item-description {
      position: absolute;
      bottom: 3rem;
      right: 4vw;
      margin-top: auto;
      p {
        text-align: right;
        margin: 0.5rem 0 0 0;
        font-size: 1.2rem;
        font-weight: 300;
        letter-spacing: 0rem;
        @media (min-width: map-get($grid-breakpoints,sm)) {
          font-size: 1.3rem;
          letter-spacing: 0.1rem;
        }
        @media (min-width: map-get($grid-breakpoints,lg)) {
          font-size: 1.5rem;
          letter-spacing: 0.2rem;
        }
      }
    }

    &-even {
      .item-title, .item-title-english {
        // margin-left: 5vw;
        // margin-right: auto;
      }
    }
    &-odd {
      .item-title, .item-title-english{
        // margin-left: auto;
        // margin-right: 5vw;
      }
    }
  }




  // .items {
  //   @extend .detail;
  //   margin-bottom: 3rem;

  //   @media (min-width: map-get($grid-breakpoints,lg)) {
  //     marigin-bottom: 0;
  //   }


  //   .item-wrap {
  //     .item-image {
  //       width: 100%;
  //       height: 20rem;
  //       object-fit: cover;
  //       margin-bottom: 1rem;
  //       @media (min-width: map-get($grid-breakpoints,lg)) {
  //         height: 12.5rem;
  //       }
  //     }



      // .item-title {

      // }

      // .item-description {

      // }

    // }
  // }
}