@import "./variables";

// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;400;700&family=Noto+Sans+TC:wght@400&family=Domus+Titling&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@200;300;400;500;600;700;800;900&family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');
html,
body {
  font-family: 'Noto Sans JP', sans-serif; /* デフォルトのフォント */
  // font-size: 16px; /* Default font size for 800px and below */

  // @media (min-width: 800px) {
  font-size: calc(8px + 1vw); /* Starts scaling past 800px up until 1600px */
  // }

  @media (min-width: 1320px) {
    font-size: 21.2px; /* Caps at 24px past 1600px */
  }
  
}

@mixin p1-font-size() {
  font-size: 1.3rem;
  line-height: 1.8;
  font-weight: 500;

  @media (min-width: map-get($grid-breakpoints,lg)) {
    font-size: 0.96rem;
  }
}

@mixin p2-font-size() {
  font-size: 1.4rem;
  line-height: 1.7;

  @media (min-width: map-get($grid-breakpoints,lg)) {
    font-size: 0.7rem;
    line-height: 1.6;
  }
}

@mixin p3-font-size() {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 1.76rem;
  font-weight: 100;
  letter-spacing: 0.15rem;

  @media (min-width: map-get($grid-breakpoints,lg)) {
    font-size: 1.32rem;
    letter-spacing: 0.28rem;
  }
}

.section-title {
  font-size: 2.5rem;
  text-align: center;
  padding-top: 3rem;
  margin-bottom: 2rem;

  .japanese,
  .english {
    display: block;
  }

  .japanese {
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
    margin-bottom: 0rem;
  }

  .english {
    font-size: 1.4rem;
    font-weight: 300;
    // font-style: italic;
    color: #777;
    margin-bottom: 0rem;
  }
}

.subsection-title {
  font-size: 1.4rem;
  text-align: center;
  // padding-top: 2rem;
  margin-bottom: 1.5rem;

  // font-weight: bold;
}

.subsection-description {
  font-size: 1.0rem;
}

@mixin h2-font-size() {
  font-family: 'Domus Titling', sans-serif;
  font-size: 2.5rem;
  letter-spacing: 0.2rem;
  font-weight: 100 !important;
  -webkit-text-stroke: 1px black;

  .japanese {
    font-family: 'Noto Sans JP', sans-serif;
    -webkit-text-stroke: 0px;
    font-size: 1.21rem;
  }

  @media (min-width: map-get($grid-breakpoints,lg)) {
    font-size: 2rem;
    letter-spacing: 0.28rem;

    .japanese {
      font-size: 0.96rem;
    }
  }
}

@mixin h3-font-size() {
  font-size: 1.7rem;
  letter-spacing: 0.2rem;
  font-weight: 400 !important;

  @media (min-width: map-get($grid-breakpoints,lg)) {
    font-size: 1.5rem;
    letter-spacing: 0.28rem;
  }
}

@mixin h4-font-size() {
  font-size: 1.5rem;
  letter-spacing: 0.25rem;
  font-weight: 600 !important;

  @media (min-width: map-get($grid-breakpoints,lg)) {
    font-size: 1.3rem;
    letter-spacing: 0.1rem;
  }
}

@mixin p1-font-size() {
  font-size: 1.3rem;
  line-height: 1.8;
  font-weight: 500;

  @media (min-width: map-get($grid-breakpoints,lg)) {
    font-size: 0.96rem;
  }
}

.h2-title {
  @include h2-font-size();
  margin-bottom: 5rem !important;

  position: relative;
  &::after {
    content: "";
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: -1.7rem;
    width: 7.5rem;
    border-bottom: 0.3rem solid $jyu-lightgreen;
    border-radius: 0.1rem;
  }

  .english {
    margin-bottom: 0.5rem;
  }

  @media (min-width: map-get($grid-breakpoints,lg)) {
    &::after {
      width: 6.5rem;
    }
  }
}

.h3-title {
  @include h3-font-size();
  display: inline-block;
  margin-bottom: 2rem !important;

  position: relative;
  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0rem;
    bottom: -0.4rem;
    height: 0.3rem;
    width: 100%;
    border-bottom: 0.3rem solid $jyu-yellow;
    border-radius: 0.1rem;
  }
}

.h4-title {
  @include h4-font-size();
  margin-bottom: 1rem !important;
}

// .h3-title {
//   text-align: left;
//   margin-bottom: 1.8rem;

//   .ja, .en {
//     font-weight: 400;
//     margin: 0.5rem 0;
//   }
//   .ja {
//     font-size: 2.0rem;
//   }
//   .en {
//     font-size: 1.4rem;
//   }
//   @media (min-width: map-get($grid-breakpoints,md)) {
//     .ja, .en {
//       font-weight: 400;
//       margin: 0.5rem 0;
//     }
//     .ja {
//       font-size: 1.5rem;
//     }
//     .en {
//       font-size: 1.1rem;
//     }
//   }
// }

.p1 { // from introduction
  @include p1-font-size();
}

.p2 { // from service descrption
  @include p2-font-size();
  letter-spacing: 0.08rem;
}

.p3 { // from position
  @include p3-font-size();
}

.p-base {
  @include p2-font-size();
  @media (min-width: map-get($grid-breakpoints,lg)) {
    @include p1-font-size();
  }
  letter-spacing: 0;
  margin: 0.5rem 0 2rem 0;
}