@import "../../styles/variables";

.image-with-description {
  margin: 0;

  .detail {
    height: auto !important;
    padding: 3rem 1rem;
    text-align: left;
    @media (min-width: map-get($grid-breakpoints,lg)) {
      padding: 0;
      height: inherit;
    }
  }

  .detail-text {
    margin: 3rem 0;
  }

  .detail-button {
    margin-bottom: 0rem;
  }

  .detail-image-wrap {
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    // margin: 2rem 0;

    .detail-image {
      width: 100%;
      height: 100%;
      object-fit: cover;

      // margin: 2rem 0;
      
      &.zoom {
        transform: scale(1.05);
        transition: transform 0.5s ease-out;
        transition-delay: 0.2s;
      }

      @media (max-width: map-get($grid-breakpoints,lg)) {
        margin: 0 0 1rem 0;
      }
    }
  }


}
