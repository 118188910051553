@import "../../styles/variables";

.our-business {
  margin-top: 1rem;
  margin-bottom: 5rem;

  h2 {
    font-family: 'Domus Titling', sans-serif;
    font-size: 2.5rem;
    color: $jyu-sub;
    letter-spacing: 0.2rem;
    font-weight: 100 !important;
    -webkit-text-stroke: 1px black;

    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -1.7rem;
      width: 7.5rem;
      border-bottom: 0.3rem solid $jyu-lightgreen;
      border-radius: 0.1rem;
    }
    @media (min-width: map-get($grid-breakpoints,lg)) {
      font-size: 2rem;
      &::after {
        width: 6.5rem;
      }
    }
  }

  .service {
    margin-top: 1.8rem;
  }

  .swiper-slide-active {
    border-left: 0.18rem solid $jyu-yellow;
    border-right: 0.18rem solid $jyu-yellow;
    // height: calc(100% * 0.8);//$icon-height;
    margin: 0;
    @media (min-width: map-get($grid-breakpoints,lg)) {
      border-right: 0.08rem solid $jyu-yellow;
      border-left: 0.08rem solid $jyu-yellow;
    }

  }


  

  .icon {
    height: 12rem;
    width: 12rem;

    max-height: 132px;
    max-width: 132px;
    margin-bottom: 1rem;

    @media (min-width: map-get($grid-breakpoints,md)) {
      height: 8rem;
      width: 8rem;
    }

    @media (min-width: 992px) {
      height: 10vw;
      width: 10vw
    }

    // @media (min-width: 1320) {
    //   height: 132px;
    //   width: 132px;
    // }
  }

  h3 {
    text-align: center;
    color: $jyu-sub;
    margin-bottom: 1.8rem;

    .ja, .en {
      font-weight: 400;
      margin: 0.5rem 0;
    }
    .ja {
      font-size: 2.0rem;
    }
    .en {
      font-size: 1.4rem;
    }
  }
  p {
    font-size: 1.4rem;
    letter-spacing: 0.08rem;
    line-height: 1.7;
    margin-top: 1rem;
    padding: 0 3.2rem;
  }

  @media (min-width: map-get($grid-breakpoints,md)) {
    margin-bottom: 1.6rem;
    h3 {
      .ja, .en {
        font-weight: 400;
        margin: 0.5rem 0;
      }
      .ja {
        font-size: 1.5rem;
      }
      .en {
        font-size: 1.1rem;
      }
    }
    p {
      font-size: 0.9rem;
      line-height: 1.6;
      margin-top: 0.8rem;
      padding: 0 2.8rem 0 4rem;
    }
  }

  @media (min-width: map-get($grid-breakpoints,lg)) {
    margin-bottom: 1.6rem;
    h3 {
      .ja, .en {
        font-weight: 400;
        margin: 0.5rem 0;
      }
      .ja {
        font-size: 1.1rem;
      }
      .en {
        font-size: 0.7rem;
      }
    }
    p {
      font-size: 0.7rem;
      line-height: 1.6;
      margin-top: 0.8rem;
      padding: 0 2.2rem 0 2.5rem;
    }
  }
}