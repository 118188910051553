@import "../../styles/variables";

.hamburger-icon {
  width: 4rem;
  height: 3.2rem;
  position: relative;
  margin: 0.2rem 0.2rem 0 0;

  @media (min-width: 576px) { 
    width: 2.5rem;
    height: 2.1rem;
  }

  span {
    background: white;
    filter: brightness(1);

    position: absolute;
    left: 0;
    right: 0;
    height: 0.14rem;
    border-radius: 0.25rem;
    transition: all 0.3s ease;

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:nth-child(3) {
      bottom: 0;
    }
  }

  &.scrolled span {
    filter: brightness(0.5);
  }

  &.active {
    span {
      background-color: white;
      filter: brightness(1);

      &:nth-child(1) {
        top: 50%;
        transform: translateY(-50%);
      }

      &:nth-child(2) {
        opacity: 0;
        height: 1rem;
      }

      &:nth-child(3) {
        bottom: 50%;
        transform: translateY(+50%);
      }
    }
  }
}
