
.title {
  h2 {
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 2rem;
  }

  .japanese,
  .english {
    display: block;
  }

  .japanese {
    font-size: 2.5rem;
    margin-bottom: 0rem;
  }

  .english {
    font-size: 1.8rem;
    font-weight: 300;
    font-style: italic;
    color: #777;
    margin-bottom: 0rem;
  }
}

.detail {
  text-align: center;

  h3 {
    margin-bottom: 1rem;
    line-height: 1.5;
    text-align: center;

    .japanese {
      display: block;
      font-size: 1.5rem;
    }

    .english {
      display: block;
      font-size: 1rem;
      font-style: italic;
      color: #777;
    }
  }
}