$jyu-yellow: #efd659;
$jyu-lightgreen: #c0d279;
$jyu-green: #5c9291;
$jyu-sub: #595757;
$jyu-accent: #cd5d4e;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  max: 1320px,
);

$primary-color: #3f51b5;
$secondary-color: #f50057;
$accent-color: #009688;
$background-color: #ffffff;
$error-color: #f44336;