@import 'common';

.image-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  color: #fff;
}

.flip-card {
  position: relative;
  width: 100%;
  height: 300px; // Update this line
  perspective: 1000px;

  &.flipped .flip-card-inner {
    transform: rotateY(180deg);
  }
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  transition: transform 0.6s;
  transform-style: preserve-3d;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
}

.flip-card-front img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.flip-card-back {
  transform: rotateY(180deg);
}