@import "../../styles/variables";

.footer {
  letter-spacing: 0.1rem;
  hr {
    // border: 0;
    height: 0.2rem;
    background-color: $jyu-sub;
  }
  span {
    color: $jyu-sub;
    padding: 0 0.8rem 0 0;

    &.title {
      font-weight: 800;
    }
    
  }
  a {
    color: $jyu-sub;
    text-decoration: none;

    &:hover {
      color: $jyu-sub;
      text-decoration: none;
    }
  }
}