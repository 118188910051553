@keyframes circle-text-rotation {
  0% {
    transform: rotate(0deg)
  }
  100% {
    transform: rotate(360deg)
  }
}

.circle-text {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  
  animation-name: circle-text-rotation;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  span {
    position: absolute;
    width: 1em;
    height: 1em;
    text-align: center;
    line-height: 1em;
  }
}