.hero-section {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 100vh;
  color: white;
}
  
.about-us {
  margin: 2rem 0;
}
  

// for test
h2 {
  &.background {
    // background-image: url('your-image-path.jpg'); // 画像のパスを指定します。
    background-size: contain; // 画像が要素の全体を覆うようにします。
    background-repeat: no-repeat; // 画像が繰り返されないようにします。
    background-position: center; // 画像が要素の中心に配置されるようにします。
    overflow: visible; // 要素からはみ出す内容を表示します。
    padding: 2rem; // 必要に応じてパディングを追加します。
  }
}