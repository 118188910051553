.image-with-description2 {
  margin-bottom: 5rem;

  .item-wrap {
    position: relative;
    width: 100%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;

    .item {
      position: relative;

      .item-text {
        position: absolute;
        left: 0;
        margin: auto;
        height: 100%;

        p {
          font-size: 1.2rem;
          font-weight: 300;
        }
      }

      .item-image {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: auto 0;
        height: 100%;
        object-fit: cover;
        border-radius: 1.5rem 0 0 0;
      }
    }

  }
}
