
.it-consulting-cases {
  margin-bottom: 5rem;
}


.swiper-button {
  &-prev, &-next {
    color: white;
    &::after {
      font-size: 1.8rem;
      font-weight: bold;
      color: #dddddd;
    }
  }
}

.swiper-pagination {
  .swiper-pagination-bullet {
    // opacity: 1;
    // border: white solid 1px;
    background-color: white;
  }

  .swiper-pagination-bullet-active {
    background-color: white;
  }
}